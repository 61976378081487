import {updateProfile } from "firebase/auth";
import { useRef,useEffect, useState } from "react";
import '../App.css'
import { signup, useAuth, auth } from "../firebase";
function SetUsername() {
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'light'
    );
  
    useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    }, [theme]);
  document.title =`Pastebin | Username`
  function e(){window.location.replace("/Upload")}
    const currentUser = useAuth();
    const displayref = useRef();
    async function handleUpdate(){
       updateProfile(currentUser,{displayName: displayref.current.value}).then( e()
       )
       

    
      }
      function e(){
        console.log(currentUser)
        console.log(currentUser)
        /* Reset Username */
        window.location.replace("/Account")

      }
  return (
    
    <div className="fields">
      
    <div  id="fields">
        <h2>Set Username</h2>
        <div>Info:</div>
  <div>{ currentUser?.email } </div>
  <div>{ currentUser?.displayName } </div>
  <input className='iput' ref={displayref}  placeholder="UserName" minLength={5} maxLength={12}/>
  <div>already a account? <a href="/login">Login</a></div>
  <div>Must be 5 characters or more</div>
  <div>Max 12 characters</div>
        <br/>
        <button className="ver1button" onClick={handleUpdate}>Update</button>
  </div>
  </div>
  )
}

export default SetUsername