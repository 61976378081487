import React from 'react';
import SignIn from '../parts/SignIn'
import LogOut from '../parts/LogOut'
import '../App.css'
import {auth} from '../firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
const style = {
    nav: `bg-gray-900 flex justify-between items-center p-4`,
    heading: `text-white text-2xl`
}

const Nav = () => {
    const [user] = useAuthState(auth)
    console.log(user)
  return (
    <div className={style.nav}>
      <br/>
      <div className='inline'>
    <input className='in' placeholder='Profile Picture URL' id='iu'></input>
    <input className='in' placeholder='DisplayName' id='disname'></input>
    </div>
      {user ? <LogOut /> : <SignIn />}
    </div>
  );
};

export default Nav;