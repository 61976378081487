import React from 'react'
import {auth} from '../firebase'

function Profile() {
    const {displayName,email,uid
    }=auth.currentUser
    console.log(auth.currentUser);
    const current = new Date();
    document.title =`Pastebin | ${displayName} | ${current}`
  return (
    <div>
        <div class="info">
          
        <img width={200} src='https://www.themeparkinsider.com/assets/PROF_NewUserIcon.png'></img>
        <p></p>
        <h2>{displayName}</h2>
        <div class="inline">
      <h3>{email}</h3>
      <h3 class="type">[Pro]</h3>
</div>
      <div>Reset Username? <a href="/username">Reset</a></div>
      <section>
      <h3>Account was made</h3>
      <p>{auth.currentUser.metadata.creationTime}</p>
      </section>
      <section>
      <h3>Sign In</h3>
      <p>{auth.currentUser.metadata.lastSignInTime}</p>
      </section>
      <h3>User ID</h3>
      <p>{uid}</p>
      <p>This profile is not public</p>
      
      </div>
    </div>
  )
}

export default Profile