import React, { useRef, useState, useEffect } from 'react';
import '../App.css'
import { auth, db } from "../firebase";
import Axios from "axios";
import {
  collection,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import Linkify from 'react-linkify';
function Front() {
 
  const {email}=auth.currentUser

  
  const [users, setUsers] = useState([]);
  const usersCollectionRef = collection(db, "users");
  const [news1, getnews1]= useState ('')
  const [imgs1, getimage1]= useState ('')
  const [more1, getmore1]= useState ('')
  const [author1, getauthor1]= useState ('')
  const [ptime1, gettime1]= useState ('')
  const [dtime1, getdtime1]= useState ('')
  const [main1, getmain1]= useState ('')

  const [news2, getnews2]= useState ('')
  const [imgs2, getimage2]= useState ('')
  const [more2, getmore2]= useState ('')
  const [author2, getauthor2]= useState ('')
  const [ptime2, gettime2]= useState ('')
  const [dtime2, getdtime2]= useState ('')
   const [main2, getmain2]= useState ('')
   const a = Math.floor(Math.random() * (24 - 1)) + 1;
   const b = Math.floor(Math.random() * (24 - 1)) + 1;
  

   const fetchMeme = () => {
    
     
    Axios.get('https://inshorts.deta.dev/news?category=all').then(
      (res) => {
        getnews1(res.data.data[a].title)
        getimage1(res.data.data[a].imageUrl)
        getmore1(res.data.data[a].readMoreUrl)
        getauthor1(res.data.data[a].author)
        gettime1(res.data.data[a].time)
        getdtime1(res.data.data[a].date)
        getmain1(res.data.data[a].content)
 
        getnews2(res.data.data[b].title)
        getimage2(res.data.data[b].imageUrl)
        getmore2(res.data.data[b].readMoreUrl)
        getauthor2(res.data.data[b].author)
        gettime2(res.data.data[b].time)
        getdtime2(res.data.data[b].date)
        getmain2(res.data.data[b].content)
      }
      );
    };


  useEffect(() => {
    const getUsers = async () => {
      const data = await getDocs(usersCollectionRef);
      setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      fetchMeme()
      
    };

    getUsers();
  },[]);
  const updateUser = async (id, age) => {
    const userDoc = doc(db, "users", id);
    const newFields = { age: age + 1 };
    await updateDoc(userDoc, newFields);
  };
 


  return (
    <div>    
      <div class="info">
        <img width={190} src='https://www.themeparkinsider.com/assets/PROF_NewUserIcon.png'></img>
      <h3>{email}</h3>
      <p>If theres no paste then come back tomorrow</p>
      </div>
      <div>
     
      </div>
      {users.map((user) => {
        return (
          <div>
            {" "}
            <p></p>
           
        <div class="fix">
      <div class="card">
  
  <div class="container">
  <h2>{user.head}</h2>
  <p></p>
  <div class="inline">
  <p>Posted By {user.postname}</p>
  <p class='type'>[Pro]</p>
  </div>
  <p></p>
  <img width={100} src='https://www.themeparkinsider.com/assets/PROF_NewUserIcon.png'></img>
  <br/>
    <p className='txt'><Linkify>{user.name}</Linkify></p>
  </div>
  <h2>Likes: {user.age}</h2>
  <p>Posted at {user.ptime}</p>
  <button class="ca"
              onClick={() => {
                updateUser(user.id, user.age);
              }}
            >
              {" "}
              <h2>🥰</h2>
            </button>
</div>
</div>
      <p></p>
          </div>
        );
      })}
  
       <h2>You may also like</h2>
  <div class="cardd">
      <img width={400} src={imgs1}></img>
      <p></p>
      <div class="cardinfo">
      <h3>By: {author1}</h3>
      <h3>{news1}</h3>
      <p>{main1}</p>
      <a href={more1}>Read more</a>
      <p>Posted at: {ptime1}</p>
      <h5>{dtime1}</h5>
      </div>
      </div>
      <p></p>
      <div class="cardd">
      <img width={400}  src={imgs2}></img>

      <p></p>

      <div class="cardinfo">
      <h2>By: {author2}</h2>
      <h3>{news2}</h3>
      <p>{main2}</p>
      <a href={more2}>Read more</a>
      <p>Posted at: {ptime2}</p>
      <h5>{dtime2}</h5>
      </div>
      </div>
  </div>
 
  )
}

export default Front