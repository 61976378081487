import { useEffect, useState } from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";


import { getStorage } from "firebase/storage";
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import {getFirestore} from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: `${process.env.REACT_APP_API_KEY}`,
    authDomain: "sell-95318.firebaseapp.com",
    projectId: "sell-95318",
    storageBucket: "sell-95318.appspot.com",
    messagingSenderId: `${process.env.REACT_APP_MESSAGE_SENDER_ID}`,
    appId: `${process.env.REACT_APP_APP_ID}`,
    measurementId:`${process.env.REACT_APP_ME_ID}`
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth()
export const db = getFirestore(app)
export const storage = getStorage(app);


export function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  
  export function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  
  export function logout() {
    return signOut(auth);
  }
  
  // Custom Hook
  export function useAuth() {
    const [ currentUser, setCurrentUser ] = useState();
  
    useEffect(() => {
      const unsub = onAuthStateChanged(auth, user => setCurrentUser(user));
      return unsub;
    }, [])
  
    return currentUser;
  }