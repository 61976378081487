import React from 'react';
import ChatRoom from './Pages/ChatRoom';
import Home from './Pages/Home';
import {Route,Routes} from 'react-router-dom'
import Account from './Pages/Account';
import Upload from './Pages/Upload';
import Tos from './Pages/Tos';
import Contact from './Pages/Contact';
import Documents from './Pages/Documents';
import Plans from './Pages/Plans';
import Krunker from './Pages/Krunker';
import PageNotFound from './Pages/PageNotFound';
import Settings from './Pages/Settings';
import Login from './Pages/Login';
import Register from './Pages/Register';
import SetUsername from './Pages/SetUsername';


function App() {
    return (   
   <div>
    <Routes>
    
    <Route path="/" element={<Home/>}/>
    <Route path="/Chat" element={<ChatRoom/>}/>
    <Route path="/Account" element={<Account/>}/>
    <Route path="/Upload" element={<Upload/>}/>
    <Route path="/TOS" element={<Tos/>}/>
    <Route path="/Contact" element={<Contact/>}/>
    <Route path="/Documents" element={<Documents/>}/>
    <Route path="/Plans" element={<Plans/>}/>
    <Route path="/documents/dogeminer/hackedmenu.html" element={<Krunker/>}/>
    <Route path="*" element={<PageNotFound/>}/>
    <Route path="/Setting/en/appearance" element={<Settings/>}/>
    <Route path='/register' element={<Register/>}/>
    <Route path='/login' element={<Login/>}/>
    <Route path='/username' element={<SetUsername/>}/>
    
    </Routes>
   
</div>
    )
}

export default App;
