import React,{useState,useEffect} from 'react'
import '../App.css'
import TOS from '../parts/TOS'
import {auth} from '../firebase'
import { useAuthState } from 'react-firebase-hooks/auth';
function Tos() {
    const [user] = useAuthState(auth);
    document.title =`Pastebin | TOS`
    const [theme, setTheme] = useState(
      localStorage.getItem('theme') || 'light'
      );
    
      useEffect(() => {
      localStorage.setItem('theme', theme);
      document.body.className = theme;
      }, [theme]);
  return (
    <div className={`${theme}`}>
    {user ? <TOS /> : null}
    </div>
  )
}

export default Tos