import "../App.css";
import Axios from "axios";
import { useState,useEffect } from "react";

function Documents() {
  document.title ="PasteBinJS | DOCUMENTS"
  const [meme, getmeme]= useState ('')
  const [title, gettitle]= useState ('')
  const [subreddit, getsubreddit]= useState ('')
  const [author, getauthor]= useState ('')
  function e(){window.location.replace("/Upload")}
  
  const fetchMeme = () => {
    Axios.get('https://meme-api.com/gimme').then(
      (res) => {
        console.log(res.data)
        getmeme(res.data.url)
        gettitle(res.data.title)
        getsubreddit(res.data.subreddit)
        getauthor(res.data.author)
      }
    );
  };
  const [theme, setTheme] = useState(
    localStorage.getItem('theme') || 'light'
    );
  
    useEffect(() => {
    localStorage.setItem('theme', theme);
    document.body.className = theme;
    }, [theme]);
  return (
    <div className={`${theme}`}>
      <div>
        <ul>
        <h1>Documents</h1>
        <li><a href="/">Home <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">
  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.707 1.5ZM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5 5 5Z"/>
</svg></a></li>
      <li><a href="/Account">Account <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"/>
</svg></a></li>
      <li><a href="/TOS">TOS <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">
  <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 .278.223.5.497.5H12z"/>
  <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>
</svg></a></li>
      <li><a href="/Chat">Chat <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat" viewBox="0 0 16 16">
  <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
</svg></a></li>
      <li><a href="/Contact">Contact <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-outbound" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"/>
</svg></a></li>
      <li><a href="/Plans">Plans</a></li>
      <li><a href="/Documents">Documents <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
  <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"/>
</svg></a></li>
      <li><a href="/Home/Store">Store <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bag" viewBox="0 0 16 16">
  <path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"/>
</svg></a></li>
      <li><a href="/Setting/en/appearance">Settings <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-brightness-high" viewBox="0 0 16 16">
  <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8zM8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0zm0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13zm8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5zM3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8zm10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0zm-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0zm9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707zM4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708z"/>
</svg></a></li>
      <li><a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">More+</a></li>
      <button onClick={e} className="mbtn"><text>Pastebin</text> <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-plus" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 5.5a.5.5 0 0 1 .5.5v1.5H10a.5.5 0 0 1 0 1H8.5V10a.5.5 0 0 1-1 0V8.5H6a.5.5 0 0 1 0-1h1.5V6a.5.5 0 0 1 .5-.5z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383zm.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg></button>
    </ul>
    </div>

      <div class="pbin">
        <div class="ms">
<hr/>
<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet" value='
 //Reddit Memes
 import Axios from "axios;
import { useState } from "react";

function App() {
 const [meme, getmeme]= useState ("")
  const [title, gettitle]= useState ("")
  const [subreddit, getsubreddit]= useState ("")
  const [author, getauthor]= useState ("")
 const fetchMeme = () => {
    Axios.get("https://meme-api.com/gimme").then(
      (res) => {
        console.log(res.data)
        getmeme(res.data.url)
        gettitle(res.data.title)
        getsubreddit(res.data.subreddit)
        getauthor(res.data.author)
      }
    );
  };
}
export default App;
'></textarea>
<hr/>
<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet" value='
 //API News
   
 const [users, setUsers] = useState([]);
 const usersCollectionRef = collection(db, "users");
 const [news1, getnews1]= useState ("")
 const [imgs1, getimage1]= useState ("")
 const [more1, getmore1]= useState ("")
 const [author1, getauthor1]= useState ("")
 const [ptime1, gettime1]= useState ("")
 const [dtime1, getdtime1]= useState ("")
 const [main1, getmain1]= useState ("")

 const [news2, getnews2]= useState ("")
 const [imgs2, getimage2]= useState ("")
 const [more2, getmore2]= useState ("")
 const [author2, getauthor2]= useState ("")
 const [ptime2, gettime2]= useState ("")
 const [dtime2, getdtime2]= useState ("")
  const [main2, getmain2]= useState ("")
  const a = Math.floor(Math.random() * (24 - 1)) + 1;
  const b = Math.floor(Math.random() * (24 - 1)) + 1;
 

  const News = () => {
   
    
   Axios.get("https://inshorts.deta.dev/news?category=all").then(
     (res) => {
       getnews1(res.data.data[a].title)
       getimage1(res.data.data[a].imageUrl)
       getmore1(res.data.data[a].readMoreUrl)
       getauthor1(res.data.data[a].author)
       gettime1(res.data.data[a].time)
       getdtime1(res.data.data[a].date)
       getmain1(res.data.data[a].content)

       getnews2(res.data.data[b].title)
       getimage2(res.data.data[b].imageUrl)
       getmore2(res.data.data[b].readMoreUrl)
       getauthor2(res.data.data[b].author)
       gettime2(res.data.data[b].time)
       getdtime2(res.data.data[b].date)
       getmain2(res.data.data[b].content)
     }
     );
   };
'></textarea>
<hr/>

<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet" value="
 //Verification Code
 var _chars = ['D','h','u','u','I','f','F','U','C','v','c','H','l','L','W','Q','e','E','M','j','K']
 var chars =   Math.floor(Math.random() * 10) + _chars[Math.floor(Math.random() * _chars.length)]   + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)+  Math.floor(Math.random() * 10)+_chars[Math.floor(Math.random() * _chars.length)]  
"></textarea>
<hr/>
<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet" value='
 //Contact Docs
 import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
function App() {
 const sendEmail = (e) => {
  e.preventDefault();
   
  emailjs.sendForm("--------", "-------", form.current, "-------")
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
};
return (
  <div>
         
  <form ref={form} onSubmit={sendEmail} class="pbin">
    <br></br>
    <textarea name="message" placeholder="@ then post title and why do you want this post to be removed" class="contacttextarea"/>
    <br></br>
    <input type="submit" value="Contact" class="contactbtn"/>
  </form>
  </div>
);
};
export default App
'></textarea>
<hr/>

<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet" value='
 //Upload Logic Firebase 

  const [updated, setUpdated] = useState("");

  const handleKeyDown = (event) => {

      setUpdated(event.target.value);

  };
 const current = new Date();
 // By default US English uses 12hr time with AM/PM
const time = current.toLocaleTimeString("en-US");
   const {displayName}=auth.currentUser
   const [newName, setNewName] = useState("");
   const [newHead, setNewHead] = useState("");
   const [newAge, setNewAge] = useState(0);
   const usersCollectionRef = collection(db, "users");
   const createUser = async (e) => {
     e.preventDefault()
     if(updated === chars){
       document.getElementById("context").innerHTML = "Redirecting..."
         console.log(chars)
     await addDoc(usersCollectionRef, { name: newName, age: Number(newAge), head: newHead,
     postname: displayName, ptime: time
     });   
     e.target.reset();
     window.location.replace("/")
    }
   if(updated !== chars){
   document.getElementById("context").innerHTML = "Error..."
   console.log("Error")
   document.getElementById("message").value = ""
   e.target.reset();
   }
   };
'></textarea>
<hr/>
<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet"  value="
 //BackUp Domains
 https://pastebin-com.pages.dev
 https://wastebin.pages.dev
 https://arok.pages.dev
 //Hidden URLS
 https://pastebin-com.pages.dev
 https://wastebin.pages.dev
 https://arok.pages.dev
 //More Soon
 //Website Version
 11th Deployment 
"></textarea>
<hr/>
<textarea  data-gramm="false" data-gramm_editor="false" spellcheck = "false"
 data-enable-grammarly="false" className="snippet" value='
 // ==UserScript==
// @name         Dogeminer 2 Cheats Infinite Diamonds && DogeCoins
// @namespace    http://tampermonkey.net/
// @version      0.6
// @description  Simple (MOD MENU) Dogeminer 2 Cheats Infinite Diamonds && DogeCoins Working 2022
// @author       Dogeware
// @match        https://*dogeminer2.com/*
// @icon         https://styles.redditmedia.com/t5_30h7f/styles/communityIcon_06tcufipqhz31.png
// @grant        none
// ==/UserScript==
var Reset = 4; //Rec
    setInterval(() => {
        //Cant Show Sercret
    },Reset);
 


'></textarea>
    </div>
    <h2> Generate An Reddit MEME </h2>
      <p></p>
      <h3>Author: {author}</h3>
      <p></p>
      <button class="ca" onClick={fetchMeme}><h1>😀</h1></button>
      <p></p>
      <p>Title: {title}</p>
      <img class="imgg" src={meme}></img>
      <h4>Subreddit: {subreddit}</h4>
    </div>
    </div>
  );
}

export default Documents;