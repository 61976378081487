import React, { useState } from 'react';
import {auth, db} from '../firebase'
import {addDoc, collection, serverTimestamp} from 'firebase/firestore'
import "../App.css"
const style = {
  form: `h-14 w-full max-w-[728px]  flex text-xl bottom-0`,
  input: `w-full h-100 text-l p-3 bg-gray-100  rounded-full text-black outline-none border-none`,
  button: `w-[20%] text-xl bg-green-500`,
  in: `bg-gray-100  rounded-full text-black outline-none border-none w-full h-100 `
};

const SendMessage = ({scroll}) => {
 
  const [input, setInput] = useState('');

  const sendMessage = async (e) => {
    e.preventDefault()
    if (input === '') {
        alert('Please enter a valid message')
        return
    }
    const {uid} = auth.currentUser
    await addDoc(collection(db, 'messages'), {
        text: input,
        name: document.getElementById('disname').value,
        img: document.getElementById('iu').value,
        uid,
        timestamp: serverTimestamp()
    })
    setInput('')
    scroll.current.scrollIntoView({behavior: 'smooth'})
  }

  return (
    <div>
    <form onSubmit={sendMessage} className={style.form}>
      <input
        value={input}
        onChange={(e) => setInput(e.target.value)}
        className={style.input}
        type='text'
        placeholder='Message'
      />
    </form>
    <h2>😀 ⚽️ 🏀 🏈 🖕 💪  👍 👎 👋 🤚 🖐 ✋ 🖖 👌 🍆 😃 😯 😡 🤬 🤯 😦 😧 😮 😲 🥱 🤕 🤑 🍑 🤠 😈 👿 👹 👺</h2>
    </div>
  );
};

export default SendMessage;