import React, {useState} from 'react';
import { auth, db } from "../firebase";
import '../App.css'
import {
  collection,
  addDoc,
} from "firebase/firestore";
var _chars = ['D','h','u','u','I','f','F','U','C','v','c','H','l','L','W','Q','e','E','M','j','K']
var chars =   Math.floor(Math.random() * 10) + _chars[Math.floor(Math.random() * _chars.length)]   + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)+  Math.floor(Math.random() * 10)+_chars[Math.floor(Math.random() * _chars.length)]  
function Paste() {
  const [updated, setUpdated] = useState('');

  const handleKeyDown = (event) => {

      setUpdated(event.target.value);

  };

  const current = new Date();
  // By default US English uses 12hr time with AM/PM
const time = current.toLocaleTimeString("en-US");
    const {displayName}=auth.currentUser
    const [newName, setNewName] = useState("");
    const [newHame, setNewHame] = useState("");
    const [newAge, setNewAge] = useState(0);
    const usersCollectionRef = collection(db, "users");
    const createUser = async (e) => {
      e.preventDefault()
      if(updated === chars){
        document.getElementById("context").innerHTML = "Redirecting..."
          console.log(chars)
      await addDoc(usersCollectionRef, { name: newName, age: Number(newAge), head: newHame,
      postname: displayName, ptime: time
      });   
      e.target.reset();
      window.location.replace("/")
     }
    if(updated !== chars){
    document.getElementById("context").innerHTML = "Error..."
    console.log("Error")
    document.getElementById("message").value = ''
    e.target.reset();
    }
    };
    
  return (
    <div>
       
      
         <form onSubmit={createUser}>
        <div class="warnning">
        <a href='TOS'>Terms of Service</a>
      <p>Volatile our TOS or your paste will be taken down</p>     
        </div>
    
      
      <p></p>
      <div className='pbin'>

       <h2>Verification Code: {chars}</h2>
       <p>Please input this into the following text box</p>
       <h2 id='context'></h2>
       <div className='inline'>
       <input class="input"  type="text"
        id="message"
        name="message"
        onChange={handleKeyDown} placeholder={chars}></input>
       
       
      
      <input class='input' required placeholder='Title...'
       onChange={(event) => {
        setNewHame(event.target.value);
      }} />
      </div>
      </div>
      <p></p>
       <br></br>
       <textarea id="obs" ng-model="commandeHdr.obs" ng-change="hasChanges.parameter=true" cols="100" rows="10" class="textarea" required
        placeholder="Info..."
        onChange={(event) => {
          setNewName(event.target.value);
        }}
      />
      <p></p>
      <div className='pbin'>
      <h2>😀 ⚽️ 🏀 🏈 🖕 💪  👍 👎 👋 🤚 🖐 ✋ 🖖 👌 🍆 👺</h2>
      <h2>😃 😯 😡 🤬 🤯 😦 😧 😮 😲 🥱 🤕 🤑 🍑 🤠 👹</h2>
      <button id="sub" type='sumbit' className='verbutton' required>Upload</button>
      <p></p>
      </div>
      
      </form>
    </div>
  )
}

export default Paste