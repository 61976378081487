import React, {useState} from 'react';
import '../App.css'
import LogOut from '../parts/LogOut';
import SignIn from '../parts/SignIn';
import {auth} from '../firebase'
import {useAuthState} from 'react-firebase-hooks/auth'
 var _chars = ['D','h','u','u','I','f','F','U','C','v','c','H','l','L','W','Q','e','E','M','j','K']
    var chars =   Math.floor(Math.random() * 10) + _chars[Math.floor(Math.random() * _chars.length)]   + Math.floor(Math.random() * 10) + Math.floor(Math.random() * 10)+  Math.floor(Math.random() * 10)+_chars[Math.floor(Math.random() * _chars.length)]  
function Krunker() {
  const current = new Date();
  document.title =`Pastebin | Dogeminer | ${current}`
    const [updated, setUpdated] = useState('');

    const handleKeyDown = (event) => {

        setUpdated(event.target.value);

    };
    const [user] = useAuthState(auth)
    function verification(){
if(updated === chars){
  document.getElementById("context").innerHTML = "Redirecting..."
    console.log(chars)
    window.location.replace("https://greasyfork.org/en/scripts/455886-dogeminer-2-cheats-infinite-diamonds-dogecoins-no-ads-simple-mod-menu")
}
if(updated !== chars){
  document.getElementById("context").innerHTML = "Error..."
  console.log("Error")
  document.getElementById("message").value = ''
}
    }
  return (
    <div>
        <ul>
        
      <h1>Dogeminer2</h1>
      {user ? <LogOut /> : <SignIn />} 
      <li><a href="/">Home</a></li>
      <li><a href="/Account">Account</a></li>
      <li><a href="https://dogeminer2.com/">Dogeminer2</a></li>  
      <li><a href="https://greasyfork.org/en">Greasyfork</a></li> 
    </ul>
        <div class="pbin">
            
         <h1>Dogeminer2 Will Open Shortly</h1>  
         <p>Follow Few Steps</p>   
       <h2>Verification Code: {chars}</h2>
       <p>Please input this into the following text box</p>
       <h2 id='context'></h2>
       <input class="vercode"  type="text"
        id="message"
        name="message"
        onChange={handleKeyDown} placeholder={chars}></input>
       <p/>
       <button className='verbutton' onClick={verification}>Verification</button>
        </div>
    </div>
  )
}

export default Krunker