import React from 'react'
import {auth} from '../firebase'
import '../App.css'

const LogOut = () => {
const signOut = () => {
    signOut(auth)
}
  return (
    <div>  
    <button onClick={() => auth.signOut()} class="logout">
        Logout
    </button>
    <p></p>
    </div>
    
  )
}

export default LogOut